export default {
  namespaced: true,
  state: {
    dimensions : {
      width : 0,
      height : 0,
    },
    order : {
      id : null,
      fabricante : null,
      fecha : null,
      referencia : null,
      state : null,
      costumer_budget : null
    },
    complements : {
      name : null,
      persiana : false,
      persiana_desc : '',
      registro_medida : null,
      registro_tipo : 1,
      comments : '',
      tapeta : null,
      tapeta_values : [0,0,0,0],
    },
    divisions : [],
  },
  getters: {
    getDimensions: state => {
      return state.dimensions
    }
  },
  mutations: {
    setOrder(state, order){
      state.order.id = order.id
      state.order.fabricante = order.fabricante
      state.order.fecha = order.fecha
      state.order.referencia = order.referencia
      state.order.state = order.state
      state.order.costumer_budget = order.costumer_budget
    },
    setDimensions (state , dimensions){
      state.dimensions.width = dimensions.width
      state.dimensions.height = dimensions.height
    },
    setComplements (state, complements){
      state.complements.name = complements.name
      state.complements.persiana = complements.persiana
      state.complements.persiana_desc = complements.persiana_desc
      state.complements.registro_medida = complements.registro_medida
      state.complements.registro_tipo = complements.registro_tipo
      state.complements.comments = complements.comments
      state.complements.tapeta = complements.tapeta
      state.complements.tapeta_values = complements.tapeta_values
    },
    setDivisions (state, divisions) {
      state.divisions = divisions
    },
  },
  actions: {},
}